import {allEnv} from 'next-runtime-env';
import {datadogRum} from '@datadog/browser-rum';
import {datadogLogs, HandlerType} from '@datadog/browser-logs';
import {isDevEnvironment} from './is-dev-environment';

const {
  NEXT_PUBLIC_DD_LOG_LEVEL = 'info',
  NEXT_PUBLIC_DD_ENV,
  NEXT_PUBLIC_DD_SAMPLE_RATE = '100',
  NEXT_PUBLIC_DD_CLIENT_TOKEN,
  NEXT_PUBLIC_DD_RUM_APP_ID,
  NEXT_PUBLIC_DD_FORWARD_CLIENT_ERRORS,
} = allEnv<IPublicEnv>();

const commonOptions = {
  clientToken: NEXT_PUBLIC_DD_CLIENT_TOKEN,
  env: NEXT_PUBLIC_DD_ENV,
  premiumSampleRate: 1,
};

const loggerHandlers: HandlerType[] = isDevEnvironment()
  ? ['console']
  : ['http'];

export const initializeDatadog = (session: StSessionContext): void => {
  if (!NEXT_PUBLIC_DD_CLIENT_TOKEN) {
    return;
  }

  datadogRum.init({
    applicationId: NEXT_PUBLIC_DD_RUM_APP_ID,
    service: 'st-visualizer-frontend',
    trackInteractions: true,
    sampleRate: parseInt(NEXT_PUBLIC_DD_SAMPLE_RATE, 10),
    ...commonOptions,
  });
  datadogLogs.init({
    service: 'st-visualizer-frontend',
    ...commonOptions,
    forwardErrorsToLogs: NEXT_PUBLIC_DD_FORWARD_CLIENT_ERRORS === 'true',
  });

  datadogLogs.logger.setLevel(NEXT_PUBLIC_DD_LOG_LEVEL);
  datadogLogs.logger.setHandler(loggerHandlers);
  datadogLogs.logger.setContext({user: session.user ?? 'anonymous-user'});

  if (session.user) {
    datadogRum.setUser({
      ...session.user,
    });
  }
};

export const logger = datadogLogs.logger;
