import App, {AppContext, AppProps} from 'next/app';
import Head from 'next/head';
import {Fragment, useEffect, JSX} from 'react';
import {initializeAnalyticsUser} from '../lib/analytics';
import {isServerSide} from '../lib/is-server-side';
import {getExtendedRequest} from '../lib/get-extended-request';

import '../styles/global.css';

type CustomAppProps = AppProps & {
  referrer: string;
  session: StSessionContext;
};

const CustomApp = ({
  Component,
  pageProps,
  referrer,
  session,
}: CustomAppProps): JSX.Element => {
  // Use effect guarantees the window object will exist (which DD attaches to)
  useEffect(() => {
    initializeAnalyticsUser(session, referrer);
  }, [referrer, session]);

  return (
    <Fragment>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Component {...pageProps} />
    </Fragment>
  );
};

CustomApp.getInitialProps = async ({ctx, ...rest}: AppContext) => {
  let session, referrer;

  if (isServerSide() && ctx.req) {
    const {state} = getExtendedRequest(ctx.req);

    session = state.session;
    referrer = state.referrer;
  }

  // calls App's `getInitialProps` and forward to page's initial props
  const appProps = await App.getInitialProps({ctx, ...rest});

  return {...appProps, session, referrer};
};

export default CustomApp;
