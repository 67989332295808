import {initializeUserProperties} from '../google-tag-manager';
import {initializeDatadog, logger} from '../datadog';
import {addAction as datadogEvent} from './services/datadog';
import {trackEvent as pendoEvent} from './services/pendo';
import {AnyAnalyticsEvent} from './types';

export * from './types';
export * from './events';

let savedReferrer = '';

/**
 * Used to track analytics events. This is an aggregate call that in turn calls
 * each of our supported analytics platforms.
 */
export function trackEvent(event: AnyAnalyticsEvent): void {
  event.context.referer ??= savedReferrer || document.referrer;
  if (typeof event.context.referer === 'string') {
    event.context.sourceSite ??= deriveSourceSite(event.context.referer) ?? '';
  }

  logger.debug('Tracking event', {event});
  pendoEvent(event);
  datadogEvent(event);
}

export function initializeAnalyticsUser(
  session: StSessionContext,
  referrer?: string | null
): void {
  savedReferrer = referrer || '';

  initializeUserProperties(session);
  initializeDatadog(session);
}

type SourceSiteConfig = {
  match: string | RegExp | (string | RegExp)[];
  sourceSite: string | null;
}[];

const sourceSiteConfig: SourceSiteConfig = [
  {
    match: ['visitingmedia.com', 'truetour.app'],
    sourceSite: 'Visiting Media',
  },
  {
    match: 'diagram.socialtables.com',
    sourceSite: 'Diagram',
  },
  {
    match: 'connect.socialtables.com',
    sourceSite: 'IFP',
  },
  {
    match: 'search.socialtables.com',
    sourceSite: 'ST White Label Search',
  },
  {
    match: 'wedding-spot.com',
    sourceSite: 'Wedding Spot',
  },
  {
    match: 'whitelabelvenuesearch.cvent.com',
    sourceSite: 'Cvent White Label Search',
  },
  {
    match: 'socialtables.com/walkthrough',
    sourceSite: 'PR3D Walkthrough',
  },
  {
    match: 'cvent.com/microsites',
    sourceSite: '3D Microsite',
  },
  {
    match: [
      'wiki.cvent.com',
      'jira.cvent.com',
      'cvent.lightning.force.com',
      'cventdev.cvent.com',
    ],
    sourceSite: 'Internal',
  },
  {
    match: 'www.cvent.com',
    sourceSite: 'CSN Search',
  },
  {
    match: [
      'socialtables.com',
      'cvent.com',
      /^(http(s)?:\/\/)?(www.)?google(.[a-z]{2,})+$/,
      /^(http(s)?:\/\/)?(www.)?bing(.[a-z]{2,})+$/,
      /^(http(s)?:\/\/)?(www.)?yahoo(.[a-z]{2,})+$/,
      'localhost:',
    ],
    sourceSite: null,
  },
];

const queryParamConfig: SourceSiteConfig = [
  {
    match: 'ifp-csn',
    sourceSite: 'IFP CSN',
  },
  {
    match: 'ifp-scp',
    sourceSite: 'IFP SCP',
  },
  {
    match: 'ifp-cvent-wl',
    sourceSite: 'IFP Cvent White Label Search',
  },
  {
    match: 'ifp-wedding-spot',
    sourceSite: 'IFP Wedding Spot',
  },
  {
    match: 'ifp-property-site',
    sourceSite: 'IFP Property Website',
  },
  {
    match: 'ifp',
    sourceSite: 'IFP',
  },
];

export function deriveSourceSite(referrer: string): string | null {
  const queryParam = new URL(window.location.href).searchParams.get(
    'sourceSite'
  );

  for (const {match, sourceSite} of queryParamConfig) {
    const matches = Array.isArray(match)
      ? match.some((m) => m === queryParam)
      : match === queryParam;

    if (matches) {
      return sourceSite;
    }
  }

  if (!referrer) {
    return null;
  }

  for (const {match, sourceSite} of sourceSiteConfig) {
    const matches = Array.isArray(match)
      ? match.some((m) => RegExp(m).test(referrer))
      : RegExp(match).test(referrer);

    if (matches) {
      return sourceSite;
    }
  }

  if (
    !RegExp(/^(http(s)?:\/\/)?(www)?[a-z0-9\-.]{3,}(\.[a-z]{2,})+\/?$/).test(
      referrer
    )
  ) {
    return null;
  }

  return 'Property Website';
}
